<template>
  <v-dialog :value="value" persistent width="900">
    <v-card>
      <v-container class="ma-0 pa-0" fluid>
        <v-data-table
          :headers="headers"
          :height="tableSize"
          :loading="loading"
          :items="items"
          :items-per-page="-1"
          dense
          fixed-header
          hide-default-footer
          no-data-text="No Items to Display"
        >

          <template #[`item.item_id`]="{ item }">
            <span v-if="item.item_id">
              {{item.item_id}}
            </span>
            <span v-else>-</span>
          </template>

          <template #[`item.qty_per_case`]="{ item }">
              <span v-if="item.qty_per_case">
                {{item.qty_per_case}}
              </span>
              <span v-else>-</span>
          </template>

          <template #[`item.last_90_days_shipped_qty`]="{ item }">
              <span v-if="item.last_90_days_shipped_qty != null">
                {{item.last_90_days_shipped_qty}}
              </span>
              <span v-else>-</span>
          </template>

          <template #[`item.is_open_stock`]="{ item }">
              <span v-if="item.is_open_stock != null"
                class = "font-weight-bold"
                :class = "item.is_open_stock ? 'success--text' : 'error--text'"
              >
                {{item.is_open_stock ? "Available" : "Not Available"}}
              </span>
              <span v-else>-</span>
          </template>

          <template #top>
            <v-toolbar class="main" dark flat>
              <v-toolbar-title>
                {{ selectedItem.item_desc }}
              </v-toolbar-title>
              <v-spacer/>
              <v-btn icon @click.stop="$emit('close')">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
           </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import AdRetails from '@/axios/ad-retails'
import { fullwidth } from "@/mixins/fullwidth"
import { displayHelpers } from "@/mixins/displayHelpers"
import { utils } from '@/mixins/utils'
export default {
  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { class: 'accent', sortable: false },
        { text: 'Item Id', class: 'accent white--text', sortable: true, filterable: true, value: 'item_id', width: '100' },
        { text: 'Description', class: 'accent white--text', sortable: true, filterable: true, value: 'item_desc', width: 'auto' },
        { text: 'UPC', class: 'accent white--text', sortable: true, filterable: true, value: 'upc', width: '145' },
        { text: 'Qty', class: 'accent white--text', sortable: true, filterable: true, value: 'qty_per_case', width: '75' },
        { text: 'Pack Size', class: 'accent white--text', sortable: true, filterable: true, value: 'pack_size', width: '75' },
        { text: 'Qty Per Shipper', align: 'center', class: 'accent white--text', sortable: true, filterable: true, value: 'component_qty', width: '135' },
        { text: 'Avg. Movement', align: 'center', sortable: true, filterable: true, class: 'accent white--text', value: 'last_90_days_shipped_qty', width: '135' },
      ]
    }
  },
  name: 'ComponentItems',
  mixins: [fullwidth, displayHelpers, utils],
  props: {
    value: Boolean,
    selectedItem: Object,
    ad_id: String
  },
  created() {
    this.initComponentItems()

    if (this.$auth.tenant === 'alliance-retail-group') {
      this.headers.push({ 
        text: 'Open Stock', 
        class: 'accent white--text', 
        sortable: true, 
        filterable: true, 
        value: 'is_open_stock', 
        width: '75' 
      })
    }
  },
  methods: {
    async initComponentItems() {
      const { feature_id, item_id } = this.selectedItem
      this.loading = true
      try {
        const res = await AdRetails.getShipperComponents(this.ad_id, feature_id, item_id)
        this.items = res?.data || []
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.noWrap {
  white-space: nowrap;
}
</style>
